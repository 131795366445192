/* .container{
    
        font-family: 'Open Sans',sans-serif;
        font-weight: 400;
        line-height: 1.7;
        color: var(--color-grey-dark-2);
        background-image: linear-gradient(to right bottom, var(--color-primary-dark), var(--color-primary-dark));
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 100vh;
    
} */

.container {
  max-width: 70rem;
  min-height: 50rem;
  margin: 5rem auto;
  background-color: #faf9f9;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .container {
    margin: 0;
    /* max-width: 100%;
    width: 100%; */
  }
}
