.header{
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
}

.user-nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.user-nav__icon, .user-nav__profile{
    padding: 0 0.8rem;
    align-self: stretch;
    align-items: center;
    display: flex;
    cursor: pointer;
    
}

.user-nav__icon:hover, .user-nav__profile:hover{
    background-color: #ccc;
}

.user-nav__icon{
    color: #999;
}

.user-nav__profile_photo{
    height: 2rem;
    width: auto;
    border-radius: 100px;
}

.header__search{
    flex: 0 0 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header__search_input{
    width: 80%;
    border-radius: 100px;
    padding: .5rem 4rem;
    background-color: #f4f2f2 ;
    border: none;
    margin-right: -3.5rem;
    transition: all .2s;
}

.header__search_input:focus{
    outline: none;
    width: 100%;
    background-color: #f0eeee;
}

.header__search_input::-webkit-input-placeholder{
    font-size: 14px;
    font-weight: 200;
    color: #ccc;
}

.header__search_button{
    border: none;
    background-color: #f4f2f2 ;
    color: #999;
}