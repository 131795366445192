.content {
  display: flex;
  flex-direction: row;
  flex: 1;

}



.sidebar {
  background-color: #333;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 20%;


  

}



.siderbar-nav__profile_photo{
    max-width: 8rem;
    max-height: 8rem;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    border: .6rem solid #ad1e47;
}


.sidebar-nav__image{
    margin: 2rem 0rem;
   display: flex;
   justify-content: center;
}


.main-content {
  flex: 1;
  background-color: #f0eeee;

}

.sidebar-nav {
  list-style: none;

  font-size: 0.8rem;
  margin: 0;
  margin-bottom: 0.2rem;
  padding-inline-start: 0;
  margin-top: 1rem;
}

.sidebar-nav__item {
  /* display: block; */
  position: relative;
}

.sidebar-nav__item::before {
  content: "";
  position: absolute;

  height: 100%;
  top: 0;
  left: 0;
  width: 3px;
  background-color: #eb2f64;
  transition: all 0.2s;
  z-index: 0;
}

.sidebar-nav__item:hover::before {
  transform: scaleX(1);
  width: 100%;
}




.sidebar-nav__item:not(:last-child) {
  margin-bottom: 0.6rem;
}

.sidebar-nav__link:link,
.sidebar-nav__link:visited {
  text-transform: uppercase;
  text-decoration: none;
  color: #faf9f9;
  display: block;
  padding: 1rem 1.3rem;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.legal {
  font-size: 0.7rem;
  color: #faf9f9;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.about {
  margin: 1rem 1rem;
  padding: 1rem;
  background-color: #faf9f9;
}

.about-header {
  line-height: 1.5rem;
}

.about-header__greet {
  display: block;
  font-family: "Saira Extra Condensed", serif;
  font-weight: 700;
  color: #343a40;
}
.about-header__greet__secondname {
  color: #eb2f64;
  text-transform: uppercase;
}

.about-header__career {
  font-family: "Saira Extra Condensed", serif;
  font-weight: 700;
  color: #343a40;
}

.about-header__adress {
  display: flex;
  /* align-items: center; */
  font-size: 0.8rem;
  margin-bottom: 2rem;
  margin-top: -0.8rem;
}
.about-header__adress__icon {
  color: grey;
  margin-right: 0.3rem;
}

.about-header__adress__icon__email {
  color: grey;
  margin-right: 0.3rem;
  margin-left: 0.6rem;
}

.about-header__adress__phone {
  color: grey;
}

.about-header__adress__email {
  color: #eb2f64;
}

.about-header__bio {
  text-align: justify;
  color: #868e96;
  margin-bottom: 3rem;
  font-size: 0.96rem;
}

.about-header__resume {
  text-decoration: none;
  color: #faf9f9;
  text-transform: uppercase;
  padding: 0.6rem;
  background-color: #eb2f64;
  font-size: 0.7rem;
  font-weight: 700;
  /* margin-bottom: 3rem; */
}

.about-header__socialmedias {
  margin-top: 5rem;
  font-size: 2.4rem;
}

.about-header__socialmedias_platform:hover {
  color: #eb2f64;
  cursor: pointer;
}

.about-header__socialmedias__linkedin {
  margin-right: 3rem;
}

.socialplatform{
color: #333;
}

.experience {
  margin: 1rem 1rem;
  padding: 1rem;
  background-color: #faf9f9;
}

.experience-field {
  /* display: block; */
  text-transform: uppercase;
  font-weight: 700;

  color: #343a40;
}

.experience-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.experience-container {
  text-transform: uppercase;
}
.experience-title {
  /* font-family: 'Saira Extra Condensed', serif; */
  line-height: 0px;
  color: #343a40;
}

.experience-firm {
  color: #868e96;
}

.experience-date {
  line-height: 0px;
  display: flex;
  align-items: start;
  color: #eb2f64;
}

.education {
  margin: 1rem 1rem;
  padding: 1rem;
  background-color: #faf9f9;
}

.education-field {
  /* display: block; */
  text-transform: uppercase;
  font-weight: 700;

  color: #343a40;
}

.education-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.education-container {
  text-transform: uppercase;
}
.education-title {
  /* font-family: 'Saira Extra Condensed', serif; */
  line-height: 0px;
  color: #343a40;
}

.education-firm {
  color: #868e96;
}

.education-date {
  line-height: 0px;
  display: flex;
  align-items: start;
  color: #eb2f64;
}

.education-studyfield {
  line-height: 0px;
}

.skills {
  margin: 1rem 1rem;
  padding: 1rem;
  background-color: #faf9f9;
}

.skills-title {
  text-transform: uppercase;
  font-weight: 700;
  color: #343a40;
}

.skills-frontend__title {
  color: #868e96;
}

.skills-body {
  display: flex;
  flex-direction: column;
}

.skills-icons {
  height: 2.4rem;
  margin-right: 1rem;
  width: auto;
}

.icon-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  width: auto;
  background-color: #343a40;
  color: white;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
 
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}

.icon-container:hover .tooltip {
  visibility: visible;
}

.skills-frontend__workflow {
  
    font-size: 0.96rem;
  color: #868e96;
  list-style: none;
  padding-inline-start: 0px;
}

.skills-frontend__workflow li {
  position: relative;
  padding-left: 30px;
}

.skills-frontend__workflow_li::before {
  content: "\2713";
  font-size: 20px;
  color: #eb2f64;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.interests {
    
  margin: 1rem 1rem;
  padding: 1rem;
  background-color: #faf9f9;
  
}

.interests-details {
  color: #868e96;
  text-align: justify;
  /* font-family: "Saira Extra Condensed", serif; */
  font-size: 0.96rem;
}

.certification {
  margin: 1rem 1rem;
  padding: 1rem;
  background-color: #faf9f9;
}

.certification-details {
  list-style: none;
  padding-inline-start: 0px;
  /* font-family: "Saira Extra Condensed", serif; */
  color: #868e96;
  font-size: 0.96rem;
}

.certification-details li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 0.7rem;
}

.certification-details li::before {
  content: "\1F3C6";
  position: absolute;

  font-size: 20px;
  color: #eb2f64;

  left: 0;
  top: 50%;
  transform: translateY(-50%);
}


@media screen and (max-width: 768px) {
    .sidebar{
        display: none;
    }

    .experience-details {
        display: flex;
        flex-direction: column;
        
      }

    .education-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 2rem;
      }  
      .about-header__adress{
        display: flex;
        flex-direction: column;
      }
      .education-title{
        line-height: normal;
      }

      .education-studyfield{
        line-height: normal;
        margin: 0;
      }

      .experience-title{
        line-height: normal;
      }
      
      .hamburger{
        padding: 1rem;
        background-color: #faf9f9;
        position: fixed;
        right: 0;
        left: 0;
        z-index: 1000;
        background-color: #eb2f64;
       
      }

      .hamburger-btn {
        background-color: transparent;
        border: none;
        color: #333;
        font-size: 24px;
        cursor: pointer;
        padding: 5px;
        outline: none; 
        
      }
      
      .hamburger-btn:hover {
        color: #555;
      }
      
      
      .about{
        margin-top: 6rem;
      }
      
      .dropdown_active ul{
        list-style: none;
        
      }

      .sidebar-nav__link{
        color: #343a40;
      }

 

 
      
}

@media screen and (min-width: 768px) {
    .hamburger{
        display: none;
       
    }   
}